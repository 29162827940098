@use "@porsche-design-system/components-js/styles" as *;

body {
  margin: 0;
  padding: $pds-spacing-fluid-medium;
  height: fit-content;
  background-color: $pds-theme-light-background-surface;
  font-family: $pds-font-family;
  font-size: $pds-font-size-text-xx-small;
}

.tile-heading {
  margin-bottom: $pds-spacing-fluid-small;
}

.application-submitted {
  p-inline-notification {
    padding: 0.25rem;
    width: fit-content;
  }
  margin-bottom: $pds-spacing-fluid-small;
}

.slider-content {
  margin-bottom: $pds-spacing-fluid-medium;
}

.accNumber {
  font-weight: $pds-font-weight-bold;
}

.account-label {
  margin-bottom: $pds-spacing-fluid-x-small;
}

.account-detail {
  padding: 15px 8px 15px 16px;
  background-color: $pds-theme-light-contrast-low;
  border-radius: $pds-border-radius-small;
  margin-bottom: $pds-spacing-fluid-x-small;
}

.bottom-text {
  padding-bottom: $pds-spacing-fluid-small;
  width: 50%;
  @include pds-media-query-max("s") {
    width: 100%;
  }
}

.ButtonPanel {
  p-button-group {
    margin-bottom: $pds-spacing-fluid-medium;
  }
}

.text-content {
  width: 200px;
}
